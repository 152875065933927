<template>
  <div>
    <div
      v-if="configStore.debug"
      id="log-wrapper"
      class="bg-pink-200 h-[200px] overflow-scroll p-3 text-xs"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import useConfigStore from "~/stores/config";
const configStore = useConfigStore();
defineOptions({
  name: "Logger",
});
</script>
