<template>
  <div>
    <ClientOnly>
      <slot />
      <Logger />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Logger from "~/components/Logger.vue";
import useQueryString from "~/composables/useQueryString";
import useConfigStore from "~/stores/config";
import useClientTypeStore from "~/stores/clientType";
import { isMobile } from "~/helpers/screenSize";
import { initToast } from "~/lib/alert";
import dayjs from "~/lib/dayjs";
import { useHead } from "#imports";

const configStore = useConfigStore();
const clientTypeStore = useClientTypeStore();
const { parsedQueryString } = useQueryString();

function changeLang() {
  const { lang } = parsedQueryString;
  const { locale } = useI18n();
  if (typeof lang === "string" && lang.length > 0) {
    locale.value = lang;
    configStore.lang = lang;
    dayjs.locale(lang);
  }
}

changeLang();
// disable zoom when embed in ios/android
useHead({
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
  ],
});

onMounted(async () => {
  initToast({
    x: "center",
    y: "top",
  });
  configStore.getConfig();
  clientTypeStore.isMobile = isMobile;
  clientTypeStore.isDesktop = !isMobile;
  const {
    setupAndroidBridge,
    setAndroidBridgeInstance,
    setupIosBridge,
    setIosBridgeInstance,
    setupWebBridge,
    setWebBridgeInstance,
    setupPlatformBridge,
  } = await import("~/composables/hybrid/platformBridge");
  setupPlatformBridge({ isWebClient: configStore.isWebClient });
  nextTick(() => {
    setupWebBridge((webBridge: Window) => {
      setWebBridgeInstance(webBridge);
    });
    setupAndroidBridge((androidBridge: any) => {
      androidBridge.init();
      setAndroidBridgeInstance(androidBridge);
    });
    setupIosBridge((iosBridge: any) => {
      setIosBridgeInstance(iosBridge);
    });
  });

  // watch(isIdle, async (newVal) => {
  //   if (!newVal) {
  //     return;
  //   }

  // });
});
</script>
